<template>
    <div class="col-sm-9 ps-4">
        <h3 class="my-3">Rx:</h3>
        <div class="border p-4 bg-light" style="min-height: 50vh">
            <div class="medicine-item">
                <div class="table-responsive prescription-table">
                    <table class="table table-striped">
                        <tr v-for="(medicine,index) in $parent.form.medicines" :key="index">
                            <td style="vertical-align:top; padding-top: 12px; width: 100px">Medicine {{ ++index }}</td>
                            <td width="auto" style="vertical-align:top" class="w-300">
                                <multiselect
                                    :class="{ 'is-invalid': $parent.errors[`medicines.${index}.medicine`] }"
                                    v-model="medicine.medicine"
                                    :options="new_transaction_items"
                                    @search-change="getMedicines"
                                    label="name_with_category"
                                    :taggable="true"
                                    tag-placeholder="Add new Medicine"
                                    placeholder="Select or add the medicine"
                                    :id="--index"
                                    @tag="addAbility"/>
                                <div v-if="$parent.errors[`medicines.${index}.medicine`]"
                                     class="invalid-feedback p-0 m-0">
                                    {{ $parent.errors[`medicines.${index}.medicine`][0] }}
                                </div>
                            </td>
                            <td style="vertical-align:top; padding-top: 12px; width: 15px">
                                Dose
                            </td>
                            <td width="150px" style="vertical-align:top">
                                <input :class="{ 'is-invalid': $parent.errors[`medicines.${index}.dose`] }"
                                       v-model="medicine.dose" type="text" class="form-control">
                                <div v-if="$parent.errors[`medicines.${index}.dose`]"
                                     class="invalid-feedback p-0 m-0">
                                    {{ $parent.errors[`medicines.${index}.dose`][0] }}
                                </div>
                            </td>
                            <td style="vertical-align:top; padding-top: 12px; width: 15px">
                                Days
                            </td>
                            <td width="70px" style="vertical-align:top">
                                <input :class="{ 'is-invalid': $parent.errors[`medicines.${index}.duration`] }"
                                       v-model="medicine.duration" type="text" class="form-control">
                                <div v-if="$parent.errors[`medicines.${index}.duration`]"
                                     class="invalid-feedback p-0 m-0">
                                    {{ $parent.errors[`medicines.${index}.duration`][0] }}
                                </div>
                            </td>
                            <td>
                                <i type="button" @click="removeMedicineItem(index)" class="text-danger fa fa-trash"></i>
                            </td>
                        </tr>
                    </table>
                </div>
                <div type="button"
                     class="add-item bg-primary py-2 px-3 text-white rounded mt-2 d-inline" @click="addMedicineItem">+
                </div>
            </div>
        </div>
        <div class="mt-3 text-center d-flex gap-2 justify-content-center prescription-btn">
            <div @click="$parent.editMode ? $parent.update('preview') : $parent.save('preview')"
                 class="bg-primary btn mt-3 px-5 py-3 text-light">
                Preview
            </div>
            <div type="submit" @click="$parent.editMode ? $parent.update('save') : $parent.save('save')"
                 class="bg-primary btn mt-3 px-5 py-3 text-light mx-3">{{ $parent.editMode ? 'Update' : 'Save' }}
            </div>
            <div @click="$router.go(-1)" class="bg-secondary btn mt-3 px-5 py-3 text-light">Cancel</div>
        </div>
        <div class="d-none" id="btn-medicine-create-modal" data-bs-toggle="modal"
             data-bs-target="#prescriptionMedicineModal">
            Add Medicine
        </div>
        <div class="modal fade" id="prescriptionMedicineModal" data-bs-backdrop="static" data-bs-keyboard="false"
             tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <form @submit.prevent="storeMedicine()">
                            <div class="mb-3 row g-2">
                                <div class="col-sm-6">
                                    <div class="col-auto">
                                        <label for="name" class="col-form-label">Medicine Name *</label>
                                    </div>
                                    <div class="col-auto">
                                        <input required :class="{ 'is-invalid': errors['name'] }"
                                               placeholder="Medicine Name"
                                               v-model="form.name" type="text"
                                               class="form-control mb-3" id="name">
                                        <div v-if="errors['name']" class="invalid-feedback">
                                            {{ errors['name'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="col-auto">
                                        <label for="name" class="col-form-label">Category*</label>
                                    </div>
                                    <div class="col-auto">
                                        <drop-down :class_name="{ 'is-invalid': errors['category_id'] }"
                                                   title="Category" v_model="category"/>
                                        <div v-if="errors['category_id']" class="invalid-feedback">
                                            {{ errors['category_id'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="col-auto">
                                        <label for="name" class="col-form-label">Quantity</label>
                                    </div>
                                    <div class="col-auto">
                                        <input placeholder="Quantity" v-model="form.quantity" type="text"
                                               class="form-control mb-3" id="quantity">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="col-auto">
                                        <label for="name" class="col-form-label">Advice</label>
                                    </div>
                                    <div class="col-auto">
                                        <input placeholder="Advice" v-model="form.advice" type="text"
                                               class="form-control mb-3" id="advice">
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex gap-4 justify-content-center">
                                <input type="submit" value="Save" class="bg-primary form-control mt-4 text-light w-25">
                                <button type="button" id="close-medicine-create-modal"
                                        class="bg-light form-control mt-4 text-center w-25"
                                        data-bs-dismiss="modal">Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Multiselect         from 'vue-multiselect';

export default {
    name      : "RightSection",
    components: {Multiselect},
    data      : () => ({
        errors   : [],
        form     : {
            name    : '',
            category: '',
            quantity: '',
            advice  : '',
            index   : '',
        },
        medicines: [],
        medicine : {}
    }),
    mounted() {
        this.getMedicines();
    },
    computed: {
        selected_item_ids() {
            return this.$parent.form.medicines.map((item, index) => {
                return item.medicine.id;
            })
        },
        new_transaction_items() {
            return this.medicines.filter(item => !this.selected_item_ids.includes(item.id));
        }
    },
    methods : {
        // add new medicine
        addAbility(newAbility, index) {
            //this.$parent.form.medicines[index].medicine = newAbility;
            this.form.name  = newAbility;
            this.form.index = index;
            document.querySelectorAll("#btn-medicine-create-modal")[0].click();
        },
        // saveMedicine
        storeMedicine() {
            let params = {
                ...this.form,
                category_id: this.form.category ? this.form.category.id : '',
            }
            ApiService.post('/medicines', params).then((res) => {
                this.errors = []
                document.querySelectorAll("#close-medicine-create-modal")[0].click();
                this.getMedicines();
                NotificationService.success(res.data.message);
                this.$parent.form.medicines[this.form.index].medicine = res.data.data;
                this.formReset();
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        // formReset
        formReset() {
            this.form = {
                name    : '',
                category: '',
                quantity: '',
                advice  : '',
                index   : '',
            }
        },
        //selectedMedicine
        selectedMedicine(medicine) {
            let prescription_medicines = this.$parent.form.medicines;
            let medicine_find          = prescription_medicines.find(p_m => p_m.medicine_id === medicine.id);

            if (typeof medicine_find === "undefined") {
                let prescription_medicine = {
                    dose    : '',
                    duration: '',
                    medicine               : medicine,
                    medicine_id               : medicine.id,
                };

                prescription_medicines.push(prescription_medicine);
            }
        },
        //dropdown lists
        getMedicines: function (search) {
            let params = {
                search: search
            };
            ApiService.get('/dropdown/medicines', {params: params}).then((res) => {
                this.medicines = res.data.data
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        addMedicineItem() {
            let prescription_medicine = {
                medicine: '',
                dose    : '',
                duration: '',
            };
            this.$parent.form.medicines.push(prescription_medicine)
        },
        removeMedicineItem(index) {
            this.$parent.form.medicines.splice(index, 1)
        }
    }
}
</script>

<style scoped>
.table > :not(:first-child) {
    border-top: 0 solid currentColor;
}

.multiselect {
    padding: 0 !important;
}
</style>
